<template>
  <div class="projectSpace" :class="{ 'projectSpace-selected': isSelected }">
    <div
      class="projectSpace__content p-3"
      :class="isOther && 'bg-primary-light'"
    >
      <div class="d-flex flex-column wd-75p h-100 justify-content-between">
        <square-container class="wd-75p">
          <img
            :alt="name"
            :class="[
              isOther ? 'projectSpace__other' : 'projectSpace__room-icon',
              isSelected ? '' : 'projectSpace__not-selected',
            ]"
            :src="
              require(`@/assets/icons/room-types/${typeName.replaceAll(
                ' ',
                '-'
              )}.png`)
            "
          />
        </square-container>

        <h5
          class="projectSpace__room-name select-disabled mb-0 w-100 mt-3 overflow-hidden"
          :class="isOther && 'text-white'"
        >
          {{ name }}
        </h5>
      </div>

      <div
        class="d-flex flex-column h-100 wd-25p justify-content-between align-items-center"
        v-if="!disableCount"
      >
        <inline-svg
          class="projectSpace__arrow"
          :src="require('../assets/icons/general/chevron-up.svg')"
          @click.stop="increment"
        />
        <h1 class="projectSpace__counter select-disabled">{{ selected }}</h1>
        <inline-svg
          class="projectSpace__arrow"
          :src="require('../assets/icons/general/chevron-down.svg')"
          @click.stop="decrement"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import SquareContainer from "@/components/SquareContainer";

export default {
  name: "ProjectSpace",
  components: { SquareContainer, InlineSvg },
  props: {
    selected: Number,
    name: String,
    isSelected: Boolean,
    disableCount: Boolean,
    typeName: String,
    typeValue: Number,
  },
  data() {
    return {
      counter: 0,
    };
  },
  computed: {
    isOther() {
      return this.name.toLowerCase() === "other";
    },
  },
  methods: {
    increment() {
      this.$emit("increment");
    },
    decrement() {
      this.$emit("decrement");
    },
  },
};
</script>

<style scoped lang="scss">
.bg-primary-light {
  background-color: var(--primary-light);
}

.projectSpace {
  --background: var(--neutral-dark);
  --border-color: var(--neutral-light);
  --text-color: var(--neutral-light);
  --counter-display: none;

  width: 100%;
  background: var(--background);
  position: relative;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  &__content {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-wrap: nowrap;
  }

  &-selected {
    --background: var(--blue-3);
    --border-color: var(--blue-2);
    --text-color: var(--neutral-light);
    --counter-display: block;
  }

  &__room-icon {
    fill: var(--text-color);
    border: 2px solid var(--border-color);
    border-radius: 12%;
    padding: 15%;
    overflow: visible;
    user-select: none;
  }

  &__room-name {
    color: var(--text-color);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__arrow {
    fill: white;
    width: 100%;
    display: var(--counter-display);
    cursor: pointer;
  }

  &__counter {
    pointer-events: none;
    display: var(--counter-display);
    color: var(--neutral-light);
  }

  &__other {
    border: 2px solid white;
    border-radius: 12%;
    padding: 15%;
    overflow: visible;
    user-select: none;
    filter: brightness(100%);
  }
}
</style>
