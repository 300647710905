<template>
  <main class="select-spaces p-4 col">
    <h2 class="font-weight-300 text-primary-light">
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores.
    </h2>
    <div class="select-spaces__grid">
      <ProjectSpace
        v-for="(space, index) in spaces"
        :is-selected="space.selected > 0"
        :selected="space.selected"
        :name="space.typeName"
        :type-value="space.typeValue"
        :type-name="space.typeName"
        @click.native="spaceClicked(index)"
        :key="index"
        @increment="$store.commit('room/addRoom', space)"
        @decrement="$store.commit('room/removeOneRoom', space)"
      />
    </div>
  </main>
</template>

<script>
import ProjectSpace from "../../components/ProjectSpace";

export default {
  name: "SelectSpaces",
  components: { ProjectSpace },
  mounted() {
    this.$store.dispatch("room/getRoomTypes");
  },
  methods: {
    spaceClicked(index) {
      if (this.spaces[index].selected > 0) {
        this.$store.commit(
          "room/removeAllRoomsWithType",
          this.spaces[index].typeValue
        );
      } else {
        this.$store.commit("room/addRoom", this.spaces[index]);
      }
    },
  },
  computed: {
    spaces() {
      return this.$store.state.room.roomTypes
        .filter((r) => r.name !== "Backend")
        .map((room) => {
          return {
            name: room.name,
            typeName: room.name,
            typeValue: room.value,
            selected: this.$store.state.room.selectedRoomTypes.filter(
              (r) => r.typeValue === room.value
            ).length,
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-spaces {
  &__grid {
    display: grid;
    place-content: center;

    $i: 1;

    $grid-breakpoints: (
      xs: 0,
      s: 1,
      md: 768px,
      xl: 1200px,
      xxl: 1600px,
    );

    @each $name, $value in $grid-breakpoints {
      @media (min-width: $value) {
        grid-template-columns: repeat(($i + 1), minmax(0, 1fr));
        gap: Min((0.75 + (($i - 1) * 0.5)) + rem, 1rem);
      }

      $i: $i + 1;
    }
  }
}
</style>
